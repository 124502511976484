<template>
  <b-container class="directory-content">
    <div class="free-title"><span>自由模式</span></div>
    <b-row align-v="center">
      <b-col cols="8" class="vocabulary-hints">
        <span class="iconfont icon-tishi"></span>
        你当前正在自由模式下训练，可选择任何模块进行学习！
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-left table-tag">词汇</b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          striped
          :fields="fields"
          :items="items"
          table-class="vocabulary-table"
          thead-tr-class="form-thead"
          tbody-class="form-tbody"
        >
          <template v-slot:cell(trainingExamName)="row">
            <div class="estimated-time">{{ row.value }}</div>
          </template>
          <template v-slot:cell(seven)="row">
            <GlobalButtonDirectory
              :state="row.value.state"
              :starNum="row.value.star"
              @beginTest="beginTest(row, 1)"
            ></GlobalButtonDirectory>
          </template>
          <template v-slot:cell(eighthUp)="row">
            <GlobalButtonDirectory
              :state="row.value.state"
              :starNum="row.value.star"
              @beginTest="beginTest(row, 1)"
            ></GlobalButtonDirectory>
          </template>
          <template v-slot:cell(eighthDown)="row">
            <GlobalButtonDirectory
              :state="row.value.state"
              :starNum="row.value.star"
              @beginTest="beginTest(row, 1)"
            ></GlobalButtonDirectory>
          </template>
          <template v-slot:cell(nine)="row">
            <GlobalButtonDirectory
              :state="row.value.state"
              :starNum="row.value.star"
              @beginTest="beginTest(row, 1)"
            ></GlobalButtonDirectory>
          </template>
          <template v-slot:cell(expectedMinutes)="row">
            <div class="estimated-time">{{ row.value }} 分钟</div>
          </template>
          <template v-slot:cell(actualMinutes)="row">
            <div class="estimated-time">{{ row.value }} 分钟</div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="page-top">
      <b-col class="text-left">语法与功能</b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="item in grammarList"
        :key="item.trainingExamId"
        lg="3"
        md="6"
        xs="12"
      >
        <GrammarDirectoryCard
          :cardData="item"
          @beginTest="beginTest(item, 2)"
        ></GrammarDirectoryCard>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getSmallModuleFreeTraining } from "@/api/english-small";
import GrammarDirectoryCard from "./components/grammar-directory-card.vue";
import GlobalButtonDirectory from "@/components/global-button/global-button-directory.vue";

import { mapMutations } from "vuex";
export default {
  components: { GlobalButtonDirectory, GrammarDirectoryCard },
  data() {
    return {
      tagId: 1,
      grammerTagId: "",
      grammarList: "",
      fields: [
        {
          key: "trainingExamName",
          label: "",
        },
        {
          key: "seven",
          label: "七年级",
        },
        {
          key: "eighthUp",
          label: "八年级上",
        },
        {
          key: "eighthDown",
          label: "八年级下",
        },
        {
          key: "nine",
          label: "九年级",
        },
        {
          key: "expectedMinutes",
          label: "预期时间",
        },
        {
          key: "actualMinutes",
          label: "实际时间",
        },
      ],
      items: [],
    };
  },
  created() {
    this.getDirectoryData();
  },
  methods: {
    ...mapMutations(["setTrainingData"]),
    getDirectoryData() {
      getSmallModuleFreeTraining({}).then((res) => {
        console.log("词汇目录", res);
        this.items =
          res.data.result.vocabularyTrainingForView.vocabularyTrainings;
        this.tagId = res.data.result.vocabularyTrainingForView.tagId;
        this.grammarList =
          res.data.result.grammarTrainingForView.grammerTrainings;
        this.grammerTagId = res.data.result.grammarTrainingForView.tagId;
      });
    },
    toSprintTest() {
      this.$router.push({
        name: "SmallModuleSprintTest",
      });
    },
    getRow(row) {
      console.log("rowshuju", row);
      return "";
    },
    beginTest(row, type) {
      console.log("row", row);
      let trainingData = "";
      let examId = "";
      if (type === 1) {
        trainingData = {
          tagId: this.tagId,
          grammerTagId: "",
          setTagId: row.item.questionSet,
          difficultyTagId: row.value.tagId,
          summaryId: row.value.summaryId,
        };
        examId = row.item.trainingExamId;
      } else {
        trainingData = {
          tagId: this.grammerTagId,
          grammerTagId: row.grammerTagId,
          setTagId: "1",
          difficultyTagId: "",
          summaryId: row.summaryId,
        };
        examId = row.trainingExamId;
      }

      this.setTrainingData(trainingData);
      this.$router.push({
        name: "SmallModuleSprintTest",
        query: {
          trainingExamId: examId,
          // module: 1,
          type: type,
          mode: "free",
          trainingExamName: row.trainingExamName
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.free-title {
  width: 172px;
  height: 49px;
  background: #ffc13b;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  font-size: 30px;
  color: white;
  font-weight: bold;
  transform: skewX(-30deg);
  position: absolute;
  display: flex;
  top: -80px;
  span {
    width: 100%;
    position: absolute;
    transform: skewX(30deg);
  }
}
.directory-content {
  background: white;
  max-width: 1445px;
  padding: 15px;
  position: relative;
  .vocabulary-hints {
    text-align: left;
    color: #2cb774;
    font-weight: bold;
    font-size: 14px;
    padding: 0.5rem 20px;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-left {
  font-weight: bold;
  margin: 10px;
  font-size: 16px;
}
.estimated-time {
  padding: 1rem;
}
</style>
<style lang="scss">
.directory-content {
  .form-thead {
    background-color: #2cb774;
    color: #fff;
    font-size: 16px;
  }
  .form-tbody,
  .btn-outline-secondary {
    color: #666;
  }
  .form-tbody {
    td {
      vertical-align: middle;
    }
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }
  .table-striped tbody tr:nth-of-type(even) {
    background-color: #eef9f4;
  }
  .table th,
  .table td {
    border: none;
  }
  .btn-outline-secondary:hover {
    background-color: transparent;
  }
}
</style>